.focus-lock {
  height: 100%;
  display: flex;
  flex: 1;
}

.full {
  width: 100% !important;
  position: absolute;
}

.small {
  width: 350px;
}