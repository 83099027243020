html {
  height: 100%;
  display: flex;
  overflow-x: hidden;
}

.App {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.App > div {
  height: 100%;
}

.router {
  height: 100%;
}

.noPointer {
  cursor: default;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./theme/fonts/Inter-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./theme/fonts/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter-Light'), url(./theme/fonts/Inter-Light.woff) format('woff');
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(./theme/fonts/Inter-Medium.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(./theme/fonts/Inter-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url(./theme/fonts/Inter-Bold.ttf) format('ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url(./theme/fonts/Inter-Bold.woff) format('woff');
}

#skiplink {
  position: absolute;
  left: 200px;
  top: 15px;
  height: 0px;
}

#skiplink:focus, #skiplink:active  {
  position: absolute;
  z-index: 999;
  color: #808080 !important;
  text-decoration: underline;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

*:focus {
  outline: orange solid 2px;
}