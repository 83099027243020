.label {
  transition: all .2s ease 0s;
  font-size: 14px !important;
  font-family: 'Inter';
  position: absolute;
  color: rgb(155, 155, 155);
  pointer-events: none;
  padding: 10px;
  left: -5px;
}

.labelFocused { 
  transition: all .2s ease 0s;
  position: absolute;
  top: -20px;
  left: 5px;
  color: '#444444' !important;
  font-size: 11px !important;
  font-family: 'Inter-Medium';
} 
