.App {
  text-align: center;
  overflow: hidden;
}

html {
  height: 100%;
  display: flex;
}

body {
  width: 100%;
}

.nav-item:hover.hover {
  background-color: #2B2B2B !important;
}

.nav-item:hover.hover > div > svg.nav-icon {
  fill: white !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

#root {
  /* display: flex; */
  height: 100%;
  overflow: hidden;
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.01); }
.darken-leaderboard:hover > div, .darken-leaderboard:hover > div > div {
  background-color: #f7f7f7 !important;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*:focus {
  outline: orange solid 2px;
}

.skipLink {
  font-family: 'Inter';
  font-size: 10px;
  padding: 10px;
  position: absolute;
  color: white;
  left: 0px;
  top: -40px;
  text-decoration: none;
  border-radius: 10px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  background: rgba(0,0,0,0.6);
  -webkit-transition: top 0.15s ease-out;
  transition: top 0.15s ease-out;
  z-index: 100;
}

.skipLink:focus {
  font-family: 'Inter';
  padding: 10px;
  position: absolute;
  color: white;
  left: 0px;
  top: 0px;
  text-decoration: none;
  border-radius: 10px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  background: rgba(0,0,0,0.6);
  z-index: 100;
}

#main {
  overflow: auto !important
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}